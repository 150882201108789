export const randomColor = () => {
    const bg = [
        {
            bg: 'bg-blue-custom',
            text: 'text-blue-custom'
        },
        {
            bg: 'bg-orange-custom',
            text: 'text-orange-custom'
        },
        {
            bg: 'bg-green-custom',
            text: 'text-blue-custom'
        },
        {
            bg: 'bg-green-custom',
            text: 'text-blue-custom'
        },
        {
            bg: 'bg-purple-custom',
            text: 'text-purple-custom'
        }
    ];

    const index = Math.floor(Math.random() * bg.length);

    return bg[index];
}
