import gql from 'graphql-tag'

export const FIND_GROUP = gql`
    query($id: ID, $first: Int!, $after: String) {
        group(id: $id) {
            id
            name
            platform
            type
            isWhatsapp
            numberEvents
            contacts {
                id
                name
                lastName
                motherLastName
                email
                phone
                status
                area
            }
            messages(first: $first, after: $after) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                    total
                    count
                    currentPage
                    lastPage
                }
                edges {
                    node {
                        id
                        title
                        description
                        typeOfMessage
                        ip
                        node
                        joinMessage
                        platform
                        isWhatsapp
                        createdAt
                        systemUser {
                            id
                            name
                            lastName
                            motherLastName
                            email
                            profile
                            status
                        }
                    }
                    cursor
                }
            }
        }
    }
`
