<script>
import {defineComponent} from 'vue';
import SendMessageGroup from "@/views/group/message/send-message.vue";

export default defineComponent({
  name: "ContainerSendMessageGroup",
  components: {
    SendMessageGroup
  }
});
</script>

<template>
  <SendMessageGroup/>
</template>
