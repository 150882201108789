<script>
import {defineComponent} from 'vue';
import {FIND_GROUP} from "@/graphql/queries/groups/find";
import {CREATE_MESSAGE} from "@/graphql/mutations/message/createMessage";
import {DELETE_MESSAGE} from "@/graphql/mutations/message/deleteMessage";
import {DELETE_CONTACT_GROUP} from "@/graphql/mutations/group/deleteContactGroup";
import {helpers, required} from "@vuelidate/validators";
import {authComputed} from "@/state/helpers";
import {useVuelidate} from "@vuelidate/core";
import {getIdWithoutHash} from "@/helpers/helper";
import {has, values} from "lodash";
import formatGraphQLErrors from "@/errors/formatGraphQLErrors";
import Swal from "sweetalert2";
import {createdAtFormat} from "@/helpers/helper";
import {getFirstLetters} from "@/helpers/words";
import {randomColor} from "@/helpers/bg-colors";

export default defineComponent({
  name: "SendMessage",
  data: () => ({
    error: null,
    submitted: false,
    group: null,
    first: 50,
    ticketGroupId: null,
    ticket: null,
    form: {
      groupId: null,
      systemUserId: null,
      title: null,
      message: null,
      ip: null,
      node: null,
      joinMessage: false
    }
  }),
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        title: {
          required: helpers.withMessage("El titulo es requerido", required),
        },
        message: {
          required: helpers.withMessage("El mensaje es requerido", required),
        },
        node: {
          required: helpers.withMessage("El node es requerido", required),
        }
      }
    }
  },
  apollo: {
    group: {
      query: FIND_GROUP,
      variables() {
        return {
          id: this.$route.params.id,
          first: this.first,
          after: null,
        }
      }, update: (data) => {
        return {
          ...data.group,
          color: randomColor()
        };
      },
      loadingKey: 'loading'
    },
  },
  mounted() {
    setTimeout(() => {
      this.ticketGroupId = process.env.VUE_APP_TICKET_GROUP;
      this.fillForm()
    }, 800);
  },
  methods: {
    getFirstLetters,
    createdAtFormat,
    fillForm() {
      this.form.groupId = this.groupId;
      this.form.systemUserId = this.currentUser?.id;
    },
    resetForm() {
      this.form.title = null;
      this.form.message = null;
      this.form.ip = null;
      this.form.node = null;
      this.form.joinMessage = false;
    },
    async sendMessageGroup() {
      try {
        this.submitted = true;
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const {data} = await this.$apollo.mutate({
          mutation: CREATE_MESSAGE,
          variables: {
            input: this.form
          }
        });

        const message = data.message;
        this.notification('success', `El mensaje con titulo ${message.title} se ha enviado satisfactoriamente`);
        this.resetForm();
        await this.refreshGroup();

        return message;
      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        if (has(dataError, 'category') && dataError.category === 'graphql') {
          dataError = dataError.message
        }

        this.error = dataError;
        this.notification('error', dataError);
      }
    },
    async showMore() {
      const {data} = await this.$apollo.queries.group.fetchMore({
        variables: {
          id: this.$route.params.id,
          first: this.first,
          after: this.group.messages.pageInfo.endCursor,
        },
      });

      this.group = data.group;
    },
    async refreshGroup() {
      const {data} = await this.$apollo.queries.group.fetchMore({
        variables: {
          id: this.$route.params.id,
          first: this.first
        },
      });

      this.group = data.group;
    },
    async deleteMessage(message) {
      try {
        const $vm = this;

        Swal.fire({
          title: 'Estás seguro de eliminar el mensaje permanentemente.?',
          text: "Se eliminará el mensaje de todas las aplicaciones.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#b82124',
          cancelButtonColor: '#515151',
          confirmButtonText: 'Eliminar',
          cancelButtonText: 'Cerrar',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {

            const {data} = await $vm.$apollo.mutate({
              mutation: DELETE_MESSAGE,
              variables: {
                id: message.id
              }
            });

            const info = data.deleteMessage;
            $vm.notification('success', `El mensaje ${info.title} se ha eliminado satisfactoriamente.`);
            await this.refreshGroup();

            return info;
          }
        })

      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        if (has(dataError, 'category') && dataError.category === 'graphql') {
          dataError = dataError.message
        }

        this.error = dataError;
        this.notification('error', dataError);
      }
    },
    async deleteContactGroup(contact) {
      try {
        const $vm = this;

        Swal.fire({
          title: `Estás seguro de desea expulsar al contact ${contact.name} del grupo permanentemente.?`,
          text: `Se eliminará el contacto ${contact.name} de todas las aplicaciones.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#b82124',
          cancelButtonColor: '#515151',
          confirmButtonText: 'Expulsar',
          cancelButtonText: 'Cerrar',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {

            const {data} = await $vm.$apollo.mutate({
              mutation: DELETE_CONTACT_GROUP,
              variables: {
                input: {
                  groupId: $vm.group.id,
                  contactId: contact.id
                }
              }
            });

            const group = data.deleteContactGroup;
            $vm.notification('success', `El contacto ${contact.name} ${contact.lastName} se ha expulsado del grupo ${group.name}.`);
            await this.refreshGroup();

            return group;
          }
        })

      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        if (has(dataError, 'category') && dataError.category === 'graphql') {
          dataError = dataError.message
        }

        this.error = dataError;
        this.notification('error', dataError);
      }
    },
    notification(type, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: type,
        title: title,
      })
    }
  },
  watch: {
    ticket: function (value) {
      if (value) {
        this.form.ticket = value;
      } else {
        delete this.form.ticket;
      }
    }
  },
  computed: {
    ...authComputed,
    groupId() {
      return this.$route.params.id;
    },
    messages() {
      return (this.group.messages.edges || []).map(edge => {
        return {
          ...edge.node,
          color: randomColor()
        };
      });
    },
    groupIdWithoutHash() {
      return getIdWithoutHash(this.groupId);
    },
    isProfileManager() {
      return ['PLATFORM_MANAGER'].includes(this.currentUser?.profile);
    },
    contacts() {
      return (this.group.contacts || []).map(contact => {
        return {
          ...contact,
          color: randomColor()
        }
      });
    }
  }
});
</script>

<template>
  <div v-if="group">
    <b-row class="mt-n4">
      <b-col lg="12">
        <b-card no-body class="mt-n4 mx-n4 mb-n5">
          <div class="bg-black">
            <b-card-body class="pb-4 mb-5">
              <b-row>
                <b-col md>
                  <b-row class="align-items-center">
                    <b-col md="auto">
                      <div class="team-profile-img avatar-md mb-md-0 mb-4 w-100 h-100">
                        <div
                            class="avatar-lg img-thumbnail rounded-circle flex-shrink-0"
                            :class="group.color.bg"
                        >

                          <div
                              class="avatar-title text-primary rounded-circle text-uppercase fs-24 position-relative"
                              :class="[group.color.bg, group.color.text]"
                          >
                            {{ getFirstLetters(group.name.replace(/\s/g, '')) }}
                          </div>

                        </div>



                      </div>
                    </b-col>
                    <b-col md>
                      <h4 class="fw-semibold text-white">
                        {{ group.name }}
                      </h4>

                      <div class="hstack gap-3 flex-wrap">
                        <div class="text-muted">
                          Grupo:
                          <span v-if="group.isWhatsapp">Whatsapp</span>
                          <span v-else>iTOCAPP</span>
                        </div>

                        <div class="vr"></div>

                        <div class="text-muted">
                          Tipo:
                          <span class="fw-medium" v-if="group.type === 'PRIVATE'">Privado</span>
                          <span class="fw-medium" v-else>Público</span>
                        </div>

                        <div class="vr"></div>

                        <div class="text-muted">
                          Plataforma:
                          <span class="fw-medium" v-if="group.platform">{{ group.platform }}</span>
                          <span class="fw-medium" v-else>N/A</span>
                        </div>

                        <div class="vr"></div>
                      </div>

                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-card-body>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col xxl="9">
        <b-card no-body>
          <b-card-body class="p-4">

            <form
                autocomplete="off"
                id="send-message-group-form"
                class="needs-validation"
                @submit.prevent="sendMessageGroup"
            >
              <b-row>
                <b-col lg="12">
                  <div class="mb-3">
                    <input
                        type="text"
                        class="form-control"
                        id="title"
                        placeholder="Titulo"
                        v-model="form.title"
                        :class="{
                          'is-invalid': submitted && v$.form.title.$error,
                        }"
                    >

                    <div v-for="(item, index) in v$.form.title.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>

                  </div>

                  <div class="mb-3">
                    <textarea
                        class="form-control"
                        id="message"
                        rows="3"
                        v-model="form.message"
                        placeholder="Mensaje"
                        :class="{
                          'is-invalid': submitted && v$.form.message.$error,
                        }"
                    >
                    </textarea>

                    <div v-for="(item, index) in v$.form.message.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mb-3">
                    <input
                        type="text"
                        class="form-control"
                        id="ip"
                        placeholder="IP"
                        v-model="form.ip"
                    >
                  </div>

                  <div class="mb-3">
                    <input
                        type="text"
                        class="form-control"
                        id="node"
                        placeholder="Nodo"
                        v-model="form.node"
                        :class="{
                          'is-invalid': submitted && v$.form.node.$error,
                        }"
                    >

                    <div v-for="(item, index) in v$.form.node.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="form-check form-switch form-switch-danger">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="joinMessage"
                          v-model="form.joinMessage"
                      >
                      <label class="form-check-label" for="joinMessage">
                        Desea Agrupar este mensaje con el anterior
                      </label>
                    </div>
                  </div>

                  <div
                      class="mb-3"
                      v-if="ticketGroupId === groupIdWithoutHash"
                  >
                    <label for="ticket" class="form-label">
                      Agregar/Actualizar TICKET
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="ticket"
                        placeholder="Número Ticket"
                        v-model="ticket"
                    >
                  </div>

                  <div class="invalid-feedback" v-if="error">
                    <span>{{ error }}</span>
                  </div>
                </b-col>

                <b-col lg="12" class="text-end">
                  <!--                  <b-link  class="btn btn-danger">Enviar Mensaje</b-link>-->

                  <b-button
                      type="submit"
                      variant="customRed"
                  >
                    Enviar Mensaje
                  </b-button>
                </b-col>
              </b-row>
            </form>

          </b-card-body>

          <b-card-body class="p-4">
            <h5 class="card-title mb-4">Mensajes</h5>

            <div data-simplebar style="height: 900px;" class="px-3 mx-n3">

              <div
                  class="d-flex mb-4"
                  v-for="(message, key) in messages"
                  :key="key"
              >
                <div
                    class="avatar-xs flex-shrink-0 me-3 img-thumbnail rounded-circle cursor-pointer"
                    :class="message.color.bg"
                >
                  <div
                      class="avatar-title text-primary rounded-circle text-uppercase fs-24 position-relative"
                      :class="[message.color.bg, message.color.text]"
                  >
                    {{ getFirstLetters(message.systemUser.name?.replace(/\s/g, '')) }}
                  </div>

                </div>


                <div class="flex-grow-1 ms-3">
                  <h5 class="fs-13">
                    {{ message.systemUser.name }}
                    escribió el
                    <small class="text-muted">{{ createdAtFormat(message.createdAt) }}</small></h5>
                  <p class="text-muted">
                    {{ message.description }}
                  </p>
                  <button
                      type="button"
                      class="btn btn-outline-danger view-btn btn-sn text-black"
                      @click="deleteMessage(message)"
                  >
                    Eliminar
                  </button>
                </div>
              </div>

              <div class="row text-center">
                <div class="col-md-12 mt-4">
                  <button class="btn btn-social-icon btn-linkedin btn-rounded"
                          @click="showMore">
                    <span>Mostrar más</span>
                  </button>
                </div>
              </div>

            </div>

          </b-card-body>
        </b-card>
      </b-col>


      <b-col xxl="3">
        <b-card no-body>
          <b-card-header class="align-items-center d-flex border-bottom-dashed">
            <b-card-title class="mb-0 flex-grow-1">Personas en el grupo</b-card-title>
          </b-card-header>

          <b-card-body>
            <div data-simplebar style="height: 635px;" class="mx-n3 px-3">
              <div class="vstack gap-3">

                <div
                    class="d-flex align-items-center"
                    v-for="(contact, key) in contacts"
                    :key="key"
                >
                  <div
                      class="avatar-xs flex-shrink-0 me-3 img-thumbnail rounded-circle cursor-pointer"
                      :class="contact.color.bg"
                  >
                    <div
                        class="avatar-title text-primary rounded-circle text-uppercase fs-24 position-relative"
                        :class="[contact.color.bg, contact.color.text]"
                    >
                      {{ getFirstLetters(contact.name?.replace(/\s/g, '')) }}
                    </div>

                  </div>

                  <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0">
                      <b-link href="#" class="text-black d-block">
                        {{ contact.name }} {{ contact.lastName }}
                      </b-link>
                    </h5>
                  </div>

                  <div class="flex-shrink-0">
                    <div class="d-flex align-items-center gap-1">
                      <b-button
                          type="button"
                          size="sm"
                          variant="transparent"
                          class="text-gray-dark-custom fs-21"
                          @click="deleteContactGroup(contact)"
                      >
                        <i class="ri-delete-bin-6-fill"></i>
                      </b-button>

                      <div
                          class="dropdown"
                          v-if="isProfileManager"
                      >
                        <button
                            class="btn btn-icon btn-sm fs-16 text-muted dropdown"
                            type="button" data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                          <i class="ri-more-fill"></i>
                        </button>

                        <ul class="dropdown-menu">
                          <li>
                            <b-link
                                class="dropdown-item"
                                @click="deleteContactGroup(contact)"
                            >
                              <i class="ri-delete-bin-6-fill text-muted me-2 align-bottom"></i>
                              Eliminar
                            </b-link>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </b-card-body>
        </b-card>


      </b-col>
    </b-row>
  </div>
</template>
