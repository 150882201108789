import {setProperty} from 'dot-prop';

export default function formatGraphQLErrors(errors) {
    const error = errors[0];
    const {extensions, message} = error;
    const {category, validation, data} = extensions;
    const result = {
        category,
        errors: null,
        message: null,
    };

    switch (category) {
        case `authentication`:
            Object.assign(result, {
                message: `Estas credenciales no coinciden con nuestros registros.`,
            });
            break;

        case `authorization`:
            Object.assign(result, {message});
            break;

        case `graphql`:
            Object.assign(result, {message: {message}});
            break;

        case `validation`:
            Object.assign(result, {message, errors: formatForValidation(validation)});
            break;

        case `user`:
            Object.assign(result, {message});
            break;

        case `internal`:
            Object.assign(result, {message: `Internal server error`});
            break;

        case `custom`:
          Object.assign(result, {message, data: formatForValidation(data)});
          break;

        default:
            break;
    }

    return result
}

function formatForValidation(errors) {
    return Object.keys(errors).reduce((carry, key) => {
        const error = errors[key];

        setProperty(
            carry,
            key.startsWith(`input.`) ? key.replace(`input.`, ``) : key,
            Array.isArray(error) ? error[0] : error
        );

        return carry;
    }, {})
}
