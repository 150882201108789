<script>
import {defineComponent} from 'vue';
import appConfig from "../../../../app.config.json";
import PageHeader from "@/components/page-header.vue";
import Layout from "@/layouts/main.vue";
import ContainerSendMessageGroup from "@/views/group/message/container-send-message-group.vue";

export default defineComponent({
  name: "ContainerMessageGroup",
  page: {
    title: 'Group',
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {
    PageHeader,
    Layout,
    ContainerSendMessageGroup
  },
  data: () => ({
    title: '',
    items: [
      {
        text: "Grupos",
        href: "/",
      },
      {
        text: "Todos",
        active: true,
      },
    ]
  }),
});
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <ContainerSendMessageGroup/>

  </Layout>
</template>
