import gql from 'graphql-tag'

export const DELETE_CONTACT_GROUP = gql`
    mutation ($input: DeleteGroupContactInput!) {
        deleteContactGroup(input: $input) {
            id
            name
            platform
            type
            isWhatsapp
            numberEvents
            contacts {
                id
                name
                lastName
                motherLastName
                email
                phone
                status
                area
            }
        }
    }
`
