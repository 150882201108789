import gql from 'graphql-tag'

export const DELETE_MESSAGE = gql`
    mutation ($id: ID!) {
        deleteMessage(id: $id) {
            id
            title
            description
            typeOfMessage
            ip
            node
            joinMessage
            platform
            isWhatsapp
            createdAt
            systemUser {
                id
                name
                lastName
                motherLastName
                email
                profile
                status
            }
        }
    }
`
